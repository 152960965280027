<template>
  <div class="navigation" :style="{height:bgcHeight}">
    <div class="navigation-nav">
      <div>
        <img style="width:252px;" src="../assets/logo_nav.png" alt="">
        <!-- <span>优屏运营管理后台</span> -->
      </div>
      <div>
        <a-dropdown
            placement="bottomCenter">
          <a-avatar
              shape="square"
              size="default"
              :style="{ backgroundColor:'#1890ff' , verticalAlign: 'middle',cursor:'pointer' }"
          >
            {{ user_name }}
          </a-avatar>
          <a-menu slot="overlay">
            <a-menu-item >
              个人信息
            </a-menu-item>
            <a-menu-item @click="log_out">
              退出登录
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
   <div class="navigation-content">
     <a-tag color="#2db7f5" class="navigation-div" @click="openLink('yunicu')">
       <div>
         <img class="imgSty" src="../assets/yunIcu.png" alt="">
       </div>
       <h2 style="color: white;">云icu管理后台</h2>
     </a-tag>
     <a-tag color="#87d068" class="navigation-div" @click="openLink('yichimeeting')">
       <div>
         <img class="imgSty" src="../assets/yichi.jpg" alt="">
       </div>
       <h2 style="color: white;">医驰会务系统</h2>
     </a-tag>
     <a-tag color="#108ee9" class="navigation-div" @click="openLink('shuju')">
       <div>
         <img class="imgSty" src="../assets/date.png" alt="">
       </div>
       <h2 style="color: white;">云icu数据分析系统</h2>
     </a-tag>
   </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      bgcHeight:null,
    }
  },
  computed:{
    user_name(){
      const user_info = window.localStorage.getItem("user_info");
      const user_name =  JSON.parse(user_info).user_name
      return user_name.slice(user_name.length-1)
    },
  },
  created() {
    this.bgcHeight = window.innerHeight + 'px'
  },
  methods:{
    //  退出登录
    log_out(){
      this.$router.push("/login")
    },
    openLink(type){ //测试环境
      let url;
      switch (type){
        case 'yunicu':
          // url = 'http://admin-dev.yunicu.com/' //测试
          url = 'https://yun-manage.yunicu.com/' //正式
          break;
        case 'shuju':
          // url = 'https://yun-manage.yunicu.com/appData/appHomepage' //测试
          url = 'https://yun-manage.yunicu.com/appData/appHomepage' //正式
          break;
        case 'yichimeeting':
          // url = 'http://admin-dev.yunicu.com/console/yichimeeting/liveMeet' //测试
          url = 'http://console.yichimeeting.com/liveMeet' //正式
          break;
      }
      window.open(url)
    }
  }
}
</script>
<style lang="scss">
.navigation{
  background-image: url("../assets/bgc.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .navigation-nav{
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    color: white;
  }
  .navigation-content{
    display: flex;
    justify-content: space-around;
    .imgSty{
      margin-top: 40px;
      width: 70px;height: 70px;border-radius: 70px;
      margin-bottom: 20px;
    }
    .navigation-div{
      width: 300px;
      height: 200px;
      border-radius: 5px;
      margin-top: 70px;
      text-align: center;
      //line-height: 200px;
      transition:transform 0.1s;
    }
    .navigation-div:hover{
      cursor: pointer;
      transform: translate(0,-10px);
    }
  }
}

</style>
